import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchPromoCodes = createAsyncThunk('promoCodes/fetchPromoCodes', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/promo-codes`, {
      params: query
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const fetchOnePromoCode = createAsyncThunk(
  'promoCodes/fetchPromoCode',
  async (id, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/promo-codes/${id}`)
      data = await response.data
      if (response.status === 200) {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const createPromoCode = createAsyncThunk('promoCodes/createPromoCode', async (payload) => {
  let data
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const response = await axiosPrivate.post(
      `/api/promo-codes`,
      {
        ...payload
      },
      config
    )
    data = await response.data
    if (response.status === 201) {
      return { status: 'success', data }
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})

export const deletePromoCodes = createAsyncThunk(
  'promoCodes/deletePromoCodes',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/promo-codes/`, {
        data: { ids }
      })
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject({
        message: err.message ? err.message : data?.message
      })
    }
  }
)

export const deletePromoCode = createAsyncThunk(
  'promoCodes/deletePromoCode',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/promo-codes/${ids[0]}`)
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const updatePromoCode = createAsyncThunk(
  'promoCodes/updatePromoCode',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const response = await axiosPrivate.put(
        `/api/promo-codes/${id}`,
        {
          ...payload
        },
        config
      )
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
