import { createSlice } from '@reduxjs/toolkit'
import { fetchBoxes, fetchOneBox, createBox, deleteBox, updateBox } from './boxesThunk'

const initialState = {
  boxes: [],
  status: 'idle',
  error: null,
  box: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const boxesSlice = createSlice({
  name: 'boxes',
  initialState,
  reducers: {
    resetBox: (state) => {
      state.box = initialState.box
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBoxes.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchBoxes.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.boxes = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchBoxes.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneBox.pending, (state) => {
      state.box.status = 'loading'
    })
    builder.addCase(fetchOneBox.fulfilled, (state, action) => {
      state.box.status = 'succeeded'
      state.box.data = action.payload.payload
    })
    builder.addCase(fetchOneBox.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createBox.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createBox.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.boxes = [payload, state.boxes.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createBox.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteBox.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteBox.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.boxes.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteBox.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateBox.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateBox.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.boxes.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateBox.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetBox } = boxesSlice.actions

export const reducer = boxesSlice.reducer

export default boxesSlice
