import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchGroups = createAsyncThunk('lockers/fetchGroups', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/groups`, {
      params: query
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const deleteGroups = createAsyncThunk('groups/deleteGroups', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/groups/`, {
      data: { ids }
    })
    data = await response.data

    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message
    })
  }
})
export const fetchOneGroup = createAsyncThunk('groups/fetchGroup', async (id, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/groups/${id}`)
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const createGroup = createAsyncThunk('groups/createGroup', async (payload) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/groups`, {
      ...payload
    })
    data = await response.data
    if (response.status === 201) {
      return { status: 'success', data }
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})
export const updateGroup = createAsyncThunk(
  'groupes/updateGroup',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/groups/${id}`, { name: payload })

      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const assignUsers = createAsyncThunk(
  'groupes/assignUsers',
  async ({ id, payload }, thunkAPI) => {
    let data

    try {
      const response = await axiosPrivate.put(`/api/groups/assign/${id}`, {
        ids: payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
export const removeUsers = createAsyncThunk(
  'groupes/removeUsers',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/groups/remove/${id}`, {
        ids: payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
