import Image from 'src/components/Image/Image'
import { PAGES } from './sidebarItems'
import NavItem from '../navItem/NavItem'
import logo from 'src/assets/images/sidebarLogo.svg'
import logoDark from 'src/assets/images/sidebarLogo_dark.svg'
import { useSelector } from 'react-redux'

const Sidebar = ({ setShowSidebar }) => {
  const theme = useSelector((state) => state.theme.mode)

  return (
    <div className="sidebar_container">
      <div className="logo_container">
        <Image src={theme === 'light' ? logo : logoDark} alt="logo" className="logo" />
        <i
          className="fa-sharp fa-solid fa-xmark close_btn"
          onClick={() => setShowSidebar(false)}
        ></i>
      </div>

      <div className="menu">
        {PAGES?.map((route, index) => {
          return <NavItem route={route} key={index} setShowSidebar={setShowSidebar} />
        })}
      </div>
    </div>
  )
}

export default Sidebar
