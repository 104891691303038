import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchBrackets = createAsyncThunk('brackets/fetchBrackets', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/brackets`, {
      params: query
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const fetchOneBracket = createAsyncThunk('brackets/fetchBracket', async (id, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/brackets/${id}`)
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const createBracket = createAsyncThunk('brackets/createBracket', async (payload) => {
  let data
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    const response = await axiosPrivate.post(
      `/api/brackets`,
      {
        ...payload
      },
      config
    )
    data = await response.data
    if (response.status === 201) {
      return { status: 'success', data }
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})

export const deleteBrackets = createAsyncThunk('brackets/deleteBrackets', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/brackets/`, {
      data: { ids }
    })
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message
    })
  }
})

export const deleteBracket = createAsyncThunk('brackets/deleteBracket', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/brackets/${ids[0]}`)
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const updateBracket = createAsyncThunk(
  'brackets/updateBracket',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const response = await axiosPrivate.put(
        `/api/brackets/${id}`,
        {
          ...payload
        },
        config
      )
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const updateBracketPosision = createAsyncThunk(
  'brackets/updateBracketPosision',
  async (payload, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/brackets/update-position`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
