import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (query) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/notifications`, {
        params: {
          ...query
        }
      })
      data = await response.data
      if (data.status === 'success') {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const fetchOneNotification = createAsyncThunk(
  'notifications/fetchNotification',
  async (id, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/notifications/${id}`)
      data = await response.data
      if (response.status === 200) {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const createNotificationForOneUser = createAsyncThunk(
  'notifications/createNotificationForOneUser',
  async ({ id, payload }) => {
    let data
    try {
      const response = await axiosPrivate.post(`/api/notifications/user/${id}`, {
        ...payload
      })
      data = await response.data

      if (response.status === 201) {
        return { status: 'success', data }
      }
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
export const createNotificationForAllUsers = createAsyncThunk(
  'notifications/createNotificationForAllUsers',
  async (payload) => {
    let data
    try {
      const response = await axiosPrivate.post(`/api/notifications/users`, {
        ...payload
      })
      data = await response.data

      if (response.status === 201) {
        return { status: 'success', data }
      }
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
export const createNotificationGroups = createAsyncThunk(
  'notifications/createNotificationForOneUser',
  async ({ ids, payload }) => {
    let data

    try {
      const response = await axiosPrivate.post(`/api/groups/multiple-notifs`, {
        ids,
        ...payload
      })
      data = await response.data

      if (response.status === 201) {
        return { status: 'success', data }
      }
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const deleteNotfications = createAsyncThunk(
  'notifications/deleteNotifications',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/notifications/`, {
        data: { ids }
      })
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject({
        message: err.message ? err.message : data?.message
      })
    }
  }
)
