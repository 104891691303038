import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/users`, {
      params: { ...query }
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const fetchSpecifiedUsers = createAsyncThunk('users/fetchUsers', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/users`, {
      params: { ...query }
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const fetchOneUser = createAsyncThunk('users/fetchUser', async (id, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/users/${id}`)
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const createUser = createAsyncThunk('users/createUser', async (payload) => {
  const url =
    payload?.role_id === 1
      ? '/api/users'
      : payload?.role_id === 2
      ? '/api/users/delivery-person'
      : '/api/users/admin'

  let data
  try {
    const response = await axiosPrivate.post(url, {
      ...payload
    })
    data = await response.data
    if (response.status === 201) {
      return { status: 'success', data }
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})

export const deleteUsers = createAsyncThunk('users/deleteUsers', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/users/`, {
      data: { ids }
    })
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message
    })
  }
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/users/${ids[0]}`)
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/users/${id}`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      console.log(err)
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const updateUserPassword = createAsyncThunk(
  'users/updateUserPassword',
  async ({ id, password }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.post(`/auth/admin/user-password-reset/${id}`, {
        password
      })

      data = await response.data

      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
