import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchBoxes = createAsyncThunk('boxes/fetchBoxes', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/boxes`, {
      params: query
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const fetchOneBox = createAsyncThunk('boxes/fetchBox', async (id, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/boxes/${id}`)
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const createBox = createAsyncThunk('boxes/createBox', async (payload) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/boxes`, {
      ...payload
    })
    data = await response.data
    if (response.status === 201) {
      return { status: 'success', data }
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})

export const deleteBoxes = createAsyncThunk('boxes/deleteBoxes', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/boxes/`, {
      data: { ids }
    })
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message
    })
  }
})

export const deleteBox = createAsyncThunk('boxes/deleteBox', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/boxes/${ids[0]}`)
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const updateBox = createAsyncThunk('boxes/updateBox', async ({ id, payload }, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.put(`/api/boxes/${id}`, {
      ...payload
    })
    data = await response.data
    if (response.status === 200) {
      return { status: 'success', data }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})
export const openBox = createAsyncThunk('api/boxes/open/', async ({ id, payload }, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.put(`/api/boxes/open/${id}`, {})
    data = await response.data
    if (response.status === 200) {
      return { status: 'success', data }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})
export const closeBox = createAsyncThunk('api/boxes/close/', async ({ id, payload }, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.put(`/api/boxes/close/${id}`, {})
    data = await response.data
    if (response.status === 200) {
      return { status: 'success', data }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})
