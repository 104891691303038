import { Suspense, useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './components/navbar/Navbar'
import { ReactComponent as Loader } from 'src/assets/images/loader.svg'
import Sidebar from './components/sidebar/Sidebar'

const DashboardLayout = () => {
  const menuRef = useRef()

  const [showSidebar, setShowSidebar] = useState(false)

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowSidebar(false)
      }
    }

    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  return (
    <div className="dashboard_layout_container">
      <div className={`sidebar ${showSidebar && 'toggle-mobile-sidebar'}`} ref={menuRef}>
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      </div>

      <div className="content_container">
        <Navbar setShowSidebar={setShowSidebar} />
        <Suspense
          fallback={
            <div className="laverit-loader-container">
              <Loader />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}

export default DashboardLayout
