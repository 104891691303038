import { createSlice } from '@reduxjs/toolkit'
import {
  fetchCategories,
  fetchOneCategory,
  createCategory,
  deleteCategory,
  updateCategory,
  updateCategoryPosision
} from './categoriesThunk'

const initialState = {
  categories: [],
  status: 'idle',
  error: null,
  category: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    resetCategory: (state) => {
      state.category = initialState.category
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.categories = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneCategory.pending, (state) => {
      state.category.status = 'loading'
    })
    builder.addCase(fetchOneCategory.fulfilled, (state, action) => {
      state.category.status = 'succeeded'
      state.category.data = action.payload.payload
    })
    builder.addCase(fetchOneCategory.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createCategory.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.categories = [payload, state.categories.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createCategory.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteCategory.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.categories.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateCategory.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.categories.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateCategory.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateCategoryPosision.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateCategoryPosision.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.categories = action?.payload?.data?.payload
    })
    builder.addCase(updateCategoryPosision.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetCategory } = categoriesSlice.actions

export const reducer = categoriesSlice.reducer

export default categoriesSlice
