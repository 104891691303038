import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchRoles = createAsyncThunk('roles/fetchRoles', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/roles`, {
      params: query
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
