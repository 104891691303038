import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const createAddress = createAsyncThunk('addresses/createAddress', async (payload) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/addresses`, {
      ...payload
    })
    data = await response.data
    if (response.status === 201) {
      return { status: 'success', data }
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})

export const updateAddress = createAsyncThunk(
  'addresses/updateAddress',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/addresses/${id}`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
