import { createSlice } from '@reduxjs/toolkit'
import {
  fetchLockers,
  fetchOneLocker,
  createLocker,
  deleteLocker,
  updateLocker
} from './lockersThunk'

const initialState = {
  lockers: [],
  status: 'idle',
  error: null,
  locker: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const lockersSlice = createSlice({
  name: 'lockers',
  initialState,
  reducers: {
    resetLocker: (state) => {
      state.locker = initialState.locker
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLockers.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchLockers.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.lockers = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchLockers.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneLocker.pending, (state) => {
      state.locker.status = 'loading'
    })
    builder.addCase(fetchOneLocker.fulfilled, (state, action) => {
      state.locker.status = 'succeeded'
      state.locker.data = action.payload.payload
    })
    builder.addCase(fetchOneLocker.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createLocker.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createLocker.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.lockers = [payload, state.lockers.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createLocker.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteLocker.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteLocker.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.lockers.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteLocker.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateLocker.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateLocker.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.lockers.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateLocker.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetLocker } = lockersSlice.actions

export const reducer = lockersSlice.reducer

export default lockersSlice
