import { createSlice } from '@reduxjs/toolkit'
import {
  fetchBrackets,
  fetchOneBracket,
  createBracket,
  deleteBracket,
  updateBracket,
  updateBracketPosision
} from './bracketsThunk'

const initialState = {
  brackets: [],
  status: 'idle',
  error: null,
  bracket: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const bracketsSlice = createSlice({
  name: 'brackets',
  initialState,
  reducers: {
    resetBracket: (state) => {
      state.bracket = initialState.bracket
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrackets.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchBrackets.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.brackets = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchBrackets.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneBracket.pending, (state) => {
      state.bracket.status = 'loading'
    })
    builder.addCase(fetchOneBracket.fulfilled, (state, action) => {
      state.bracket.status = 'succeeded'
      state.bracket.data = action.payload.payload
    })
    builder.addCase(fetchOneBracket.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createBracket.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createBracket.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.brackets = [payload, state.brackets.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createBracket.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteBracket.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteBracket.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.brackets.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteBracket.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateBracket.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateBracket.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.brackets.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateBracket.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateBracketPosision.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateBracketPosision.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.brackets = action?.payload?.data?.payload
    })
    builder.addCase(updateBracketPosision.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetBracket } = bracketsSlice.actions

export const reducer = bracketsSlice.reducer

export default bracketsSlice
