import { createSlice } from '@reduxjs/toolkit'
import { fetchPaymentMethods } from './paymentMethodsThunk'

const initialState = {
  paymentMethods: [],
  status: 'idle',
  error: null,
  paymentMethod: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    resetPaymentMethod: (state) => {
      state.paymentMethod = initialState.paymentMethod
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentMethods.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.paymentMethods = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchPaymentMethods.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetPaymentMethod } = paymentMethodsSlice.actions

export const reducer = paymentMethodsSlice.reducer

export default paymentMethodsSlice
