import React from 'react'
import ReactDOM from 'react-dom/client'
import './app/_App.scss'
import App from './app/App'

import './i18n'

import { Provider } from 'react-redux'
import store from './store'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer
      position={toast.POSITION.TOP_RIGHT}
      autoClose={2000}
      toastClassName="toast-margin"
    />
  </Provider>
)
