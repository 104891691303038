import { PropTypes } from 'prop-types'
import { Navigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? <Navigate to="/dashboard" /> : children
}

GuestGuard.propTypes = {
  children: PropTypes.node
}

export default GuestGuard
