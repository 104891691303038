import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? children : <Navigate to="/login" />
}

AuthGuard.protoTypes = {
  children: PropTypes.node
}

export default AuthGuard
