import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchTransactions = createAsyncThunk(
  'transactions/fetchTransactions',
  async (query) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/transactions`, {
        params: query
      })
      data = await response.data
      if (data.status === 'success') {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const fetchOneTransaction = createAsyncThunk(
  'transactions/fetchTransaction',
  async (id, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/transactions/${id}`)
      data = await response.data
      if (response.status === 200) {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const createTransaction = createAsyncThunk(
  'transactions/createTransaction',
  async (payload) => {
    let data
    try {
      const response = await axiosPrivate.post(`/api/transactions`, {
        ...payload
      })
      data = await response.data
      if (response.status === 201) {
        return { status: 'success', data }
      }
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const deleteTransactions = createAsyncThunk(
  'transactions/deleteTransactions',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/transactions/`, {
        data: { ids }
      })
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject({
        message: err.message ? err.message : data?.message
      })
    }
  }
)

export const deleteTransaction = createAsyncThunk(
  'transactions/deleteTransaction',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/transactions/${ids[0]}`)
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const updateTransaction = createAsyncThunk(
  'transactions/updateTransaction',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/transactions/${id}`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const fetchTransactionsByUserId = createAsyncThunk(
  'transactions/fetchTransactionsByUserId',
  async (id, thunkAPI) => {

    let data
    try {
      const response = await axiosPrivate.get(`/api/transactions/user/${id}`)
      data = await response.data
      if (data.status === 'success') {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)
