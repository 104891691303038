import { createSlice } from '@reduxjs/toolkit'
import { fetchCart } from './cartThunk'

const initialState = {
  cart: [],
  status: 'idle',
  error: null,
  loading: false
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCart: (state) => {
      state = initialState
    },
    noLoading: (state) => {
      state.loading = true
    },
    resetloading: (state) => {
      state.loading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCart.pending, (state, action) => {
      const { noLoading } = action.meta.arg
      if (!noLoading) state.status = 'loading'
    })
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      const { payload } = action.payload
      state.cart = payload.cart
      state.total = payload.total_price
      state.quantity = payload.total_quantity
      state.delivery = payload.delivery_price
      state.status = 'succeeded'
    })
    builder.addCase(fetchCart.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})
export const { resetCart } = cartSlice.actions

export const reducer = cartSlice.reducer

export default cartSlice
