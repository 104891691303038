import { createSlice } from '@reduxjs/toolkit'
import { fetchGroups, fetchOneGroup } from './groupsThunk'

const initialState = {
  status: 'idle',
  groups: [],
  currentGroup: {
    users: []
  },
  createdGroup: {},
  metadata: {}
}

const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    resetGroup: (state) => {
      state.currentGroup = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroups.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.groups = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchGroups.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    builder.addCase(fetchOneGroup.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchOneGroup.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.currentGroup = payload
    })
    builder.addCase(fetchOneGroup.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})
export const { resetGroup } = groupSlice.actions

export const reducer = groupSlice.reducer

export default groupSlice
