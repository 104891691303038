import axios from 'axios'
import Cookies from 'js-cookie'
import { isValidToken } from 'src/contexts/jwtAuthContext'

const BASE_URL = process.env.REACT_APP_BASE_URL
const axiosPrivate = axios.create({
  baseURL: BASE_URL
})

export const axiosMock = axios.create()

axiosMock.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
)

axiosPrivate.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${Cookies.get('accessToken')}`
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const previousRequest = error?.config
    if (
      error?.response?.status === 400 &&
      error?.response?.data === 'jwt expired' &&
      !previousRequest?.sent
    ) {
      previousRequest.sent = true
      if (!isValidToken(error?.config?.params?.refresh_token)) {
        Cookies.remove('refreshToken')
        Cookies.remove('accessToken')
      }
      const response = await axiosPrivate.post('auth/token', null, {
        params: {
          grant_type: 'refresh_token',
          refresh_token: Cookies.get('refreshToken')
        }
      })
      const newAccessToken = response.data.access_token
      Cookies.set('accessToken', newAccessToken)
      Cookies.set('refreshToken', response.data.refresh_token)
      previousRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
      return axiosPrivate(previousRequest)
    } else if (error?.response?.status === 401 && error.response.statusText === 'Unauthorized') {
      const response = await axiosPrivate.post('auth/token', null, {
        params: {
          grant_type: 'refresh_token',
          refresh_token: Cookies.get('refreshToken')
        }
      })
      Cookies.set('accessToken', response.data.access_token)
      return axiosPrivate(previousRequest)
    }
    return Promise.reject(error)
  }
)

export default axiosPrivate
