import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pagination: {
    limit: 10,
    page: '1',
    sort_by: 'id.asc'
  },
  query: {}
}

const utilitySlice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = action?.payload
    },
    setFilterQuery: (state, action) => {
      state[`${action?.payload?.path}`]= action?.payload?.query
    }
  }
})
export const { setPagination, setFilterQuery } = utilitySlice.actions
export const reducer = utilitySlice.reducer

export default utilitySlice
