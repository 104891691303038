import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import axiosPrivate from '../utils/axios'
import { ReactComponent as Loader } from 'src/assets/images/loader.svg'
import LaveritLoader from 'src/components/laveritLoader/LaveritLoader'
import Cookies from 'js-cookie'

const BASE_URL = process.env.REACT_APP_BASE_URL

const axiosInstance = axios.create({
  baseURL: BASE_URL
})

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }

  const decoded = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const setAccessSession = (accessToken) => {
  if (accessToken) {
    Cookies.set('accessToken', accessToken)
    axiosPrivate.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    Cookies.remove('accessToken')

    Cookies.set('accessToken', accessToken)
    delete axiosPrivate.defaults.headers.common.Authorization
  }
}

const setRefreshSession = (refreshToken) => {
  if (refreshToken) {
    Cookies.set('refreshToken', refreshToken, {
      expires: new Date((jwtDecode(refreshToken).exp - 5) * 1000)
    })
  } else {
    Cookies.remove('refreshToken')
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user
      }
    }
    case 'LOGIN': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    }
    case 'REGISTER': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    default: {
      return { ...state }
    }
  }
}

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
})

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)

  const login = async (email, password) => {
    const response = await axiosInstance.post('/auth/admins/login', {
      email,
      password
    })
    const { access_token, refresh_token } = response.data
    setAccessSession(access_token)

    setRefreshSession(refresh_token)
    Cookies.set('accessToken', access_token)
    const responseUser = await axiosPrivate.get('/api/users/me')
    const { payload: user } = responseUser.data

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    })
  }

  const logout = async () => {
    await axiosPrivate.post('/auth/logout')
    setAccessSession(null)
    setRefreshSession(null)
    dispatch({ type: 'LOGOUT', isAuthenticated: false, user: null })
  }

  const register = async (email, name, password) => {
    const response = await axiosInstance.post('/api/account/register', {
      email,
      name,
      password
    })
    const { access_token: accessToken, user, refresh_token: refreshToken } = response.data

    Cookies.set('accessToken', accessToken)
    Cookies.set('refreshToken', refreshToken, {
      expires: new Date((jwtDecode(refreshToken).exp - 5) * 1000)
    })

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    })
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = Cookies.get('accessToken')
        const refreshToken = Cookies.get('refreshToken')

        if (!accessToken) {
          return dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }

        if (!isValidToken(accessToken)) {
          const response = await axiosPrivate.post('auth/token', null, {
            params: {
              grant_type: 'refresh_token',
              refresh_token: refreshToken
            }
          })
          const { access_token, user, refresh_token } = response.data
          setAccessSession(access_token)
          setRefreshSession(refresh_token)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          })
        }

        axiosPrivate.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        const response = await axiosPrivate.get('/api/users/me')
        const { payload: user } = response.data
        return setTimeout(() => {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          })
        }, 1800)
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialize()
  }, [])

  if (!state.isInitialized) {
    return (
      <div className="auth_loader">
        <Loader />
        <LaveritLoader />
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
