import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchShippingCosts = createAsyncThunk(
  'shippingCosts/fetchShippingCosts',
  async (query) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/delivery-prices`, {
        params: query
      })
      data = await response.data
      if (data.status === 'success') {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const fetchOneShippingCost = createAsyncThunk(
  'shippingCosts/fetchShippingCost',
  async (id, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/delivery-prices/${id}`)
      data = await response.data
      if (response.status === 200) {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const createShippingCost = createAsyncThunk(
  'shippingCosts/createShippingCost',
  async (payload) => {
    let data
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const response = await axiosPrivate.post(
        `/api/delivery-prices`,
        {
          ...payload
        },
        config
      )
      data = await response.data
      if (response.status === 201) {
        return { status: 'success', data }
      }
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const deleteShippingCosts = createAsyncThunk(
  'shippingCosts/deleteShippingCosts',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/delivery-prices/`, {
        data: { ids }
      })
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject({
        message: err.message ? err.message : data?.message
      })
    }
  }
)

export const deleteShippingCost = createAsyncThunk(
  'shippingCosts/deleteShippingCost',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/delivery-prices/${ids[0]}`)
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const updateShippingCost = createAsyncThunk(
  'shippingCosts/updateShippingCost',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/delivery-prices/${id}`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
