import { createSlice } from '@reduxjs/toolkit'
import {
  fetchPromoCodes,
  fetchOnePromoCode,
  createPromoCode,
  deletePromoCode,
  updatePromoCode
} from './promoCodesThunk'

const initialState = {
  promoCodes: [],
  status: 'idle',
  error: null,
  promoCode: {
    data: [],
    status: 'idle',
    error: null
  },

  metadata: {}
}

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    resetPromoCode: (state) => {
      state.promoCode = initialState.promoCode
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPromoCodes.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchPromoCodes.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.promoCodes = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchPromoCodes.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOnePromoCode.pending, (state) => {
      state.promoCode.status = 'loading'
    })
    builder.addCase(fetchOnePromoCode.fulfilled, (state, action) => {
      state.promoCode.status = 'succeeded'
      state.promoCode.data = action.payload.payload
    })
    builder.addCase(fetchOnePromoCode.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createPromoCode.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createPromoCode.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.promoCodes = [payload, state.promoCodes.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createPromoCode.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deletePromoCode.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deletePromoCode.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.promoCodes.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deletePromoCode.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updatePromoCode.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updatePromoCode.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.promoCodes.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updatePromoCode.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetPromoCode } = promoCodesSlice.actions

export const reducer = promoCodesSlice.reducer

export default promoCodesSlice
