import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

const NavItem = ({ route, setShowSidebar }) => {
  const { t } = useTranslation('routes')
  const { pathname } = useLocation()
  const path = pathname.slice(1).split('/')
  const link = path.length > 1 ? '/' + path[0] + '/' + path[1] : '/' + path[0]

  return (
    <div className="nav_item_container">
      {route?.subHeader && (
        <p className="sub_header">
          <i className="fa-solid fa-caret-right icon_color"></i>
          &nbsp;&nbsp;
          {t(route?.subHeader.toLowerCase()).toUpperCase()}
        </p>
      )}

      {route?.items?.map((item, index) => (
        <Link
          to={item?.link}
          key={index}
          className={`item ${link === item?.link && 'active'}`}
          onClick={() => {
            setShowSidebar(false)
          }}
        >
          <i className={`${item?.icon} icon_color`}></i>
          &nbsp;&nbsp;
          {t(item?.label.toLowerCase()).toUpperCase()}
        </Link>
      ))}
    </div>
  )
}

export default NavItem
