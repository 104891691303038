import { createSlice } from '@reduxjs/toolkit'
import {
  fetchUsersNumber,
  fetchOrdersNumber,
  fetchTotalRevenue,
  fetchAveragePrice,
  fetchPrdRevenue,
  fetchSalesStats,
  fetchOrdersStatus,
  fetchDeliveryManStats
} from './dashboardThunk'
const initialState = {
  status: 'idle',
  TotalRevenue: {},
  RevenuePerDate: {},
  usersNumber: {},
  ordersNumber: {},
  averagePriceOrder: {},
  salesStats: {},
  ordersStatus: {},
  deliveryMan: ''
}
const dashboardSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTotalRevenue.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchTotalRevenue.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action
      const data = {
        ...payload.payload
      }

      state.TotalRevenue = data
    })
    builder.addCase(fetchTotalRevenue.rejected, (state, action) => {
      state.status = 'failed'
    })
    builder.addCase(fetchUsersNumber.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchUsersNumber.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action?.payload
      const data = {
        ...payload
      }
      state.usersNumber = data
    })
    builder.addCase(fetchUsersNumber.rejected, (state, action) => {
      state.status = 'failed'
    })
    builder.addCase(fetchOrdersNumber.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrdersNumber.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action?.payload
      const data = {
        ...payload,
        start_date: action?.meta?.arg.start_date,
        end_date: action?.meta?.arg.end_date
      }
      state.ordersNumber = data
    })
    builder.addCase(fetchOrdersNumber.rejected, (state, action) => {
      state.status = 'failed'
    })
    builder.addCase(fetchAveragePrice.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchAveragePrice.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action?.payload
      const data = {
        ...payload,
        start_date: action?.meta?.arg.start_date,
        end_date: action?.meta?.arg.end_date
      }

      state.averagePriceOrder = data
    })
    builder.addCase(fetchAveragePrice.rejected, (state, action) => {
      state.status = 'failed'
    })
    builder.addCase(fetchPrdRevenue.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action?.payload
      const data = {
        ...payload,
        start_date: action?.meta?.arg.start_date,
        end_date: action?.meta?.arg.end_date
      }

      state.RevenuePerDate = data
    })
    builder.addCase(fetchPrdRevenue.rejected, (state, action) => {
      state.status = 'failed'
    })
    builder.addCase(fetchPrdRevenue.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchSalesStats.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action?.payload
      const data = {
        data: [...payload],
        start_date: action?.meta?.arg.start_date,
        end_date: action?.meta?.arg.end_date
      }

      state.salesStats = data
    })
    builder.addCase(fetchSalesStats.rejected, (state, action) => {
      state.status = 'failed'
    })
    builder.addCase(fetchSalesStats.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrdersStatus.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action?.payload
      const { total, ...statuses } = payload

      const data = {
        total,
        data: statuses,
        start_date: action?.meta?.arg.start_date,
        end_date: action?.meta?.arg.end_date
      }

      state.ordersStatus = data
    })
    builder.addCase(fetchOrdersStatus.rejected, (state, action) => {
      state.status = 'failed'
    })
    builder.addCase(fetchOrdersStatus.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchDeliveryManStats.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action
      state.deliveryMan = payload
    })
    builder.addCase(fetchDeliveryManStats.rejected, (state, action) => {
      state.status = 'failed'
    })
    builder.addCase(fetchDeliveryManStats.pending, (state) => {
      state.status = 'loading'
    })
  }
})

export const reducer = dashboardSlice.reducer
export default dashboardSlice
