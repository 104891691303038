import { createSlice } from '@reduxjs/toolkit'
import {
  fetchProducts,
  fetchOneProduct,
  createProduct,
  deleteProduct,
  updateProduct,
  updateProductPosision
} from './productsThunk'

const initialState = {
  products: [],
  status: 'idle',
  error: null,
  product: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetProduct: (state) => {
      state.product = initialState.product
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.products = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneProduct.pending, (state) => {
      state.product.status = 'loading'
    })
    builder.addCase(fetchOneProduct.fulfilled, (state, action) => {
      state.product.status = 'succeeded'
      state.product.data = action.payload.payload
    })
    builder.addCase(fetchOneProduct.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createProduct.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createProduct.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.products = [payload, state.products.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createProduct.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteProduct.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.products.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateProduct.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.products.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateProduct.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateProductPosision.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateProductPosision.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.products = action?.payload?.data?.payload
    })
    builder.addCase(updateProductPosision.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetProduct } = productsSlice.actions

export const reducer = productsSlice.reducer

export default productsSlice
