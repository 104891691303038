import routes, { setUpRoutes } from '../routes/routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from '../contexts/jwtAuthContext'
import { useSelector } from 'react-redux'
const App = () => {
  const theme = useSelector((state) => state.theme.mode)

  return (
    <div className="App" id={theme}>
      <AuthProvider>
        <Router>{setUpRoutes(routes)}</Router>
      </AuthProvider>
    </div>
  )
}

export default App
