import { createSlice } from '@reduxjs/toolkit'
import {
  fetchServices,
  fetchOneService,
  createService,
  deleteService,
  updateService
} from './servicesThunk'

const initialState = {
  services: [],
  status: 'idle',
  error: null,
  service: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    resetService: (state) => {
      state.service = initialState.service
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.services = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchServices.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneService.pending, (state) => {
      state.service.status = 'loading'
    })
    builder.addCase(fetchOneService.fulfilled, (state, action) => {
      state.service.status = 'succeeded'
      state.service.data = action.payload.payload
    })
    builder.addCase(fetchOneService.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createService.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createService.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.services = [payload, state.services.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createService.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteService.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.services.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteService.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateService.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateService.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.services.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateService.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetService } = servicesSlice.actions

export const reducer = servicesSlice.reducer

export default servicesSlice
