import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/orders`, {
      params: query
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const fetchOneOrder = createAsyncThunk('orders/fetchOrder', async (id, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/orders/${id}`)
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const createOrder = createAsyncThunk('orders/createOrder', async ({ id, payload }) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/orders/admin/add/${id}`, {
      ...payload
    })
    data = await response.data

    if (response.status === 200) {
      return data
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})

export const deleteOrders = createAsyncThunk('orders/deleteOrders', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/orders/`, {
      data: { ids }
    })
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message
    })
  }
})

export const deleteOrder = createAsyncThunk('orders/deleteOrder', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/orders/${ids[0]}`)
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const updateOrder = createAsyncThunk(
  'orders/updateOrder',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/orders/${id}`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const updateOrderProducts = createAsyncThunk(
  'orders/updateOrderProducts',
  async (payload, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/orders/admin/update`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const assignBox = createAsyncThunk('orders/assignBox', async (payload, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.put(
      `/api/orders/assign-box
      `,
      {
        ...payload
      }
    )
    data = await response.data
    if (response.status === 200) {
      return { status: 'success', data }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})

export const fetchOrdersByUserId = createAsyncThunk(
  'orders/fetchOrdersByUserId',
  async (id, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/orders/user/${id}`)
      data = await response.data
      if (data.status === 'success') {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)
export const assignDelivery = createAsyncThunk(
  'orders/assignDelivery',
  async (payload, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/orders/assign`, payload)
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
export const fetchDeliveryPerson = createAsyncThunk('orders/fetchDeliveryPerson', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/users`, {
      params: { ...query, role_id: 2 }
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const submitOrder = createAsyncThunk('orders/submitOrder', async ({ id, payload }) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/orders/admin/add/${id}`, {
      ...payload
    })
    data = await response.data
    if (response.status === 201) {
      return { status: 'success', data }
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})
