import { createSlice } from '@reduxjs/toolkit'
import { createAddress } from './addressesThunk'

const initialState = {
  address: {},
  status: 'idle',
  error: null
}

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    resetAddress: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createAddress.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createAddress.fulfilled, (state, action) => {
      const { payload } = action.payload
      state.address = payload
      state.status = 'succeeded'
    })
    builder.addCase(createAddress.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})
export const { resetAddress } = addressSlice.actions

export const reducer = addressSlice.reducer

export default addressSlice
