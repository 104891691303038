import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchUsersNumber = createAsyncThunk('stats/fetchUsersNumber', async (query) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/stats/users-number`, {
      ...query
    })
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const fetchOrdersNumber = createAsyncThunk('stats/ordersNumber', async (query) => {
  let data

  try {
    const response = await axiosPrivate.post(`/api/stats/orders-number`, {
      ...query
    })
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const fetchTotalRevenue = createAsyncThunk('stats/total-revenue', async (query) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/stats/total-revenue`, {
      ...query
    })
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const fetchPrdRevenue = createAsyncThunk('stats/revenue-per-period', async (query) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/stats/revenue-per-period`, {
      ...query
    })
    data = await response.data

    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const fetchAveragePrice = createAsyncThunk('stats/orders-average-price', async (query) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/stats/orders-average-price`, {
      ...query
    })
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const fetchSalesStats = createAsyncThunk('stats/sales-stats', async (query) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/stats/sales-stats`, {
      ...query
    })
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const fetchOrdersStatus = createAsyncThunk('stats/orders-status', async (query) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/stats/orders-status`, {
      ...query
    })
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const fetchDeliveryManStats = createAsyncThunk(
  'stats/delivery-person-stats',
  async (query) => {
    let data

    try {
      const response = await axiosPrivate.post(
        `/api/stats/delivery-person-stats`,
        {
          ...query
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'arraybuffer'
        }
      )

      data = await response.data
      if (response.status === 200) {
        return data
      }

      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)
