import { createSlice } from '@reduxjs/toolkit'
import { fetchUsers, fetchOneUser, createUser, deleteUser, updateUser } from './usersThunk'

const initialState = {
  users: [],
  status: 'idle',
  error: null,
  user: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = initialState.user
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { payload } = action.payload
        state.users = payload?.payload
        state.metadata = payload?.metadata
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })

      .addCase(fetchOneUser.pending, (state) => {
        console.log(state)
        state.user.status = 'loading'
      })
      .addCase(fetchOneUser.fulfilled, (state, action) => {
        console.log('first')
        state.user.status = 'succeeded'
        state.user.data = action.payload.payload
      })
      .addCase(fetchOneUser.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })

      .addCase(createUser.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const payload = action?.payload?.data
        state.users = [payload, state.users.filter((item) => item?.id !== payload?.id)]
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })

      .addCase(deleteUser.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { payload } = action.payload
        state.users.filter((item) => item.id !== payload.id)
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })

      .addCase(updateUser.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.users.filter((item) => item.id !== action.payload.id)
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
  }
})

export const { resetUser } = usersSlice.actions

export const reducer = usersSlice.reducer

export default usersSlice
