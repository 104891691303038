import { createSlice } from '@reduxjs/toolkit'
import {
  fetchOrders,
  fetchOneOrder,
  createOrder,
  deleteOrder,
  updateOrder,
  fetchOrdersByUserId,
  fetchDeliveryPerson
} from './ordersThunk'

const initialState = {
  orders: [],
  status: 'idle',
  error: null,
  order: {
    data: [],
    status: 'idle',
    error: null
  },
  userOrders: {
    data: [],
    status: 'idle',
    error: null
  },
  deliveryPersons: {
    data: [],
    status: 'idle',
    error: null,
    metadata: {}
  },

  metadata: {}
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetOrder: (state) => {
      state.order = initialState.order
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.orders = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneOrder.pending, (state) => {
      state.order.status = 'loading'
    })
    builder.addCase(fetchOneOrder.fulfilled, (state, action) => {
      state.order.status = 'succeeded'
      state.order.data = action.payload.payload
    })
    builder.addCase(fetchOneOrder.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createOrder.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.orders = [payload, state.orders.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createOrder.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteOrder.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteOrder.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.orders.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteOrder.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOrdersByUserId.pending, (state) => {
      state.userOrders.status = 'loading'
    })
    builder.addCase(fetchOrdersByUserId.fulfilled, (state, action) => {
      state.userOrders.status = 'succeeded'
      state.userOrders.data = action.payload.payload
    })
    builder.addCase(fetchOrdersByUserId.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateOrder.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateOrder.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.orders.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateOrder.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    builder.addCase(fetchDeliveryPerson.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchDeliveryPerson.fulfilled, (state, action) => {
      state.deliveryPersons.status = 'succeeded'
      const { payload } = action.payload

      state.deliveryPersons.data = payload?.payload
      state.deliveryPersons.metadata = payload?.metadata
    })
    builder.addCase(fetchDeliveryPerson.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetOrder } = ordersSlice.actions

export const reducer = ordersSlice.reducer

export default ordersSlice
