export const PATH = {
  ROOT: '/',
  INDEX: '',
  ERROR: '*',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',

  ORDERS: '/dashboard/orders',
  ORDERS_UPDATE: '/dashboard/orders/edit/:id',
  ORDERS_DETAILS: '/dashboard/orders/details/:id',
  ORDERS_UPDATE_PRODUCTS: '/dashboard/orders/products/:id',

  LOCKERS: '/dashboard/lockers',
  LOCKERS_CREATE: '/dashboard/lockers/create',
  LOCKERS_UPDATE: '/dashboard/lockers/edit/:id',

  BOX: '/dashboard/boxes',
  BOX_CREATE: '/dashboard/boxes/create',
  BOX_UPDATE: '/dashboard/boxes/edit/:id',

  DELIVERIES: '/dashboard/deliveries',
  DELIVERIES_CREATE: '/dashboard/deliveries/create',
  DELIVERIES_UPDATE: '/dashboard/deliveries/edit/:id',

  PICKUPS: '/dashboard/pickups',
  PICKUPS_CREATE: '/dashboard/pickups/create',
  PICKUPS_UPDATE: '/dashboard/pickups/edit/:id',

  STATE: '/dashboard/states',
  STATE_CREATE: '/dashboard/states/create',
  STATE_UPDATE: '/dashboard/states/edit/:id',

  TRANSACTIONS: '/dashboard/transactions',
  TRANSACTIONS_CREATE: '/dashboard/transactions/create',
  TRANSACTIONS_UPDATE: '/dashboard/transactions/edit/:id',

  GROUP: '/dashboard/groups',
  GROUP_CREATE: '/dashboard/groups/create',
  GROUP_UPDATE: '/dashboard/groups/edit/:id',
  GROUP_DETAILS: '/dashboard/groups/details/:id',

  NOTIFICATION: '/dashboard/notifications',
  NOTIFICATION_CREATE: '/dashboard/notifications/create',
  NOTIFICATION_DETAILS: '/dashboard/notifications/details/:id',

  PRODUCTS: '/dashboard/products',
  PRODUCTS_CREATE: '/dashboard/products/create',
  PRODUCTS_UPDATE: '/dashboard/products/edit/:id',

  USERS: '/dashboard/users',
  USERS_CREATE: '/dashboard/users/create',
  USERS_UPDATE: '/dashboard/users/edit/:id',
  USERS_DETAILS: '/dashboard/users/details/:id',
  USERS_CART: '/dashboard/users/cart/:id',
  ADD_CART: '/dashboard/users/cart/:id/add',
  USERS_ORDER: '/dashboard/users/create/order/user/:id',

  DELIVERY: '/dashboard/delivery-person',
  DELIVERY_CREATE: '/dashboard/delivery-person/create',
  DELIVERY_STATS: '/dashboard/delivery-person/stats/:id',
  DELIVERY_DETAILS: '/dashboard/delivery-person/details/:id',
  DELIVERY_UPDATE: '/dashboard/delivery-person/edit/:id',

  ADMINS: '/dashboard/admins',
  ADMINS_CREATE: '/dashboard/admins/create',
  ADMINS_DETAILS: '/dashboard/admins/details/:id',
  ADMINS_UPDATE: '/dashboard/admins/edit/:id',

  BRACKETS: '/dashboard/main-categories',
  BRACKETS_CREATE: '/dashboard/main-categories/create',
  BRACKETS_UPDATE: '/dashboard/main-categories/edit/:id',

  CATEGORIES: '/dashboard/sub-categories',
  CATEGORIES_CREATE: '/dashboard/sub-categories/create',
  CATEGORIES_UPDATE: '/dashboard/sub-categories/edit/:id',

  SERVICES: '/dashboard/services',
  SERVICES_CREATE: '/dashboard/services/create',
  SERVICES_UPDATE: '/dashboard/services/edit/:id',

  NOTIFICATIONS: '/dashboard/notifications',
  NOTIFICATIONS_CREATE: '/dashboard/notifications/create',
  NOTIFICATIONS_UPDATE: '/dashboard/notifications/edit/:id',
  NOTIFICATIONS_DETAILS: '/dashboard/notifications/details/:id',

  PROMOCODE: '/dashboard/promo-code',
  PROMOCODE_CREATE: '/dashboard/promo-code/create',
  PROMOCODE_UPDATE: '/dashboard/promo-code/edit/:id',

  SHIPPINGCOST: '/dashboard/shipping-cost'
}
