import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchReductionTypes = createAsyncThunk(
  'reductionTypes/fetchReductionTypes',
  async (query) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/reduction-types`, {
        params: query
      })
      data = await response.data
      if (data.status === 'success') {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const fetchOneReductionType = createAsyncThunk(
  'reductionTypes/fetchReductionType',
  async (id, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/reduction-types/${id}`)
      data = await response.data
      if (response.status === 200) {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const createReductionType = createAsyncThunk(
  'reductionTypes/createReductionType',
  async (payload) => {
    let data
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      const response = await axiosPrivate.post(
        `/api/reduction-types`,
        {
          ...payload
        },
        config
      )
      data = await response.data
      if (response.status === 201) {
        return { status: 'success', data }
      }
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)

export const deleteReductionTypes = createAsyncThunk(
  'reductionTypes/deleteReductionTypes',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/reduction-types/`, {
        data: { ids }
      })
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject({
        message: err.message ? err.message : data?.message
      })
    }
  }
)

export const deleteReductionType = createAsyncThunk(
  'reductionTypes/deleteReductionType',
  async (ids, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.delete(`/api/reduction-types/${ids[0]}`)
      data = await response.data
      if (response.status === 200) {
        return { data, status: 'success' }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const updateReductionType = createAsyncThunk(
  'reductionTypes/updateReductionType',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/reduction-types/${id}`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
