import { combineReducers } from '@reduxjs/toolkit'
import themeReducer from './slices/theme/themeSlice'
import { reducer as productsReducer } from './slices/products/productsSlice'
import { reducer as categoriesReducer } from './slices/categories/categoriesSlice'
import { reducer as usersReducer } from './slices/users/usersSlice'
import { reducer as promoCodesReducer } from './slices/promoCodes/promoCodesSlice'
import { reducer as reductionTypesReducer } from './slices/reductionTypes/reductionTypesSlice'
import { reducer as shippingCostsReducer } from './slices/shippingCosts/shippingCostsSlice'
import { reducer as ordersReducer } from './slices/orders/ordersSlice'
import { reducer as lockersReducer } from './slices/lockers/lockersSlice'
import { reducer as statesReducer } from './slices/states/statesSlice'
import { reducer as servicesReducer } from './slices/services/servicesSlice'
import { reducer as boxesReducer } from './slices/boxes/boxesSlice'
import { reducer as rolesReducer } from './slices/roles/rolesSlice'
import { reducer as bracketsReducer } from './slices/brackets/bracketsSlice'
import { reducer as transactionsReducer } from './slices/transactions/transactionsSlice'
import { reducer as paymentMethodsReducer } from './slices/paymentMethods/paymentMethodsSlice'
import { reducer as notificationsReducer } from './slices/notifications/notificationsSlice'
import { reducer as utilityReducer } from './slices/utility/utilitySlice'
import { reducer as dashboardReducer } from './slices/dashboard/dashboardSlice'
import { reducer as groupsReducer } from './slices/groups/groupsSlice'
import { reducer as cartReducer } from './slices/cart/cartSlice'
import { reducer as addressReducer } from './slices/addresses/addressesSlice'

const rootReducer = combineReducers({
  theme: themeReducer,
  products: productsReducer,
  categories: categoriesReducer,
  users: usersReducer,
  promoCodes: promoCodesReducer,
  reductionTypes: reductionTypesReducer,
  shippingCosts: shippingCostsReducer,
  orders: ordersReducer,
  lockers: lockersReducer,
  states: statesReducer,
  services: servicesReducer,
  boxes: boxesReducer,
  roles: rolesReducer,
  brackets: bracketsReducer,
  transactions: transactionsReducer,
  paymentMethods: paymentMethodsReducer,
  notifications: notificationsReducer,
  utility: utilityReducer,
  stats: dashboardReducer,
  groups: groupsReducer,
  cart: cartReducer,
  address: addressReducer
})

export default rootReducer
