import { createSlice } from '@reduxjs/toolkit'
import {
  fetchShippingCosts,
  fetchOneShippingCost,
  createShippingCost,
  deleteShippingCost,
  updateShippingCost
} from './shippingCostsThunk'

const initialState = {
  shippingCosts: [],
  status: 'idle',
  error: null,
  shippingCost: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const shippingCostsSlice = createSlice({
  name: 'shippingCosts',
  initialState,
  reducers: {
    resetShippingCost: (state) => {
      state.shippingCost = initialState.shippingCost
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShippingCosts.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchShippingCosts.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.shippingCosts = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchShippingCosts.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneShippingCost.pending, (state) => {
      state.shippingCost.status = 'loading'
    })
    builder.addCase(fetchOneShippingCost.fulfilled, (state, action) => {
      state.shippingCost.status = 'succeeded'
      state.shippingCost.data = action.payload.payload
    })
    builder.addCase(fetchOneShippingCost.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createShippingCost.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createShippingCost.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.shippingCosts = [
        payload,
        state.shippingCosts.filter((item) => item?.id !== payload?.id)
      ]
    })
    builder.addCase(createShippingCost.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteShippingCost.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteShippingCost.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.shippingCosts.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteShippingCost.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateShippingCost.pending, (state) => {
      state.shippingCost.status = 'loading'
    })
    builder.addCase(updateShippingCost.fulfilled, (state, action) => {
      state.shippingCost.status = 'succeeded'
      state.shippingCost.data = action?.payload?.data?.payload
    })
    builder.addCase(updateShippingCost.rejected, (state, action) => {
      state.shippingCost.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetShippingCost } = shippingCostsSlice.actions

export const reducer = shippingCostsSlice.reducer

export default shippingCostsSlice
