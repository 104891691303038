import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { AccountMenu } from './components/AccountMenu'
import SwitchBtn from 'src/components/switchBtn/SwitchBtn'
import { useTranslation } from 'react-i18next'
import Image from 'src/components/Image/Image'
import en from 'src/assets/icon/en.png'
import fr from 'src/assets/icon/fr.png'

const Navbar = ({ setShowSidebar }) => {
  const { t, i18n } = useTranslation('routes')

  const { pathname } = useLocation()
  const [lang, setLang] = useState(i18n?.language?.toString())

  const formatPathname = (pathname) => {
    const arr = pathname.substring(1).split('?')[0].split('/')
    return arr.length === 1
      ? t(arr[0].toLowerCase()).toUpperCase()
      : t(arr[1].toLowerCase()).toUpperCase()
  }

  const onChangeLanguage = (e) => {
    const language = lang === 'fr' ? 'en' : 'fr'
    i18n.changeLanguage(language)
    setLang(language)
  }

  return (
    <div className="navbar_container">
      <div className="path">
        <i className="fa-solid fa-bars menu_bars" onClick={() => setShowSidebar(true)}></i>

        <span className="path_title">
          <i className="fa-solid fa-caret-right path_icon"></i>
          &nbsp;&nbsp;{formatPathname(pathname)}
        </span>
      </div>

      <div className="settings">
        <SwitchBtn />

        <Image
          src={lang === 'fr' ? fr : en}
          alt="lang"
          className="flag"
          onClick={onChangeLanguage}
        />

        <AccountMenu />
      </div>
    </div>
  )
}

export default Navbar
