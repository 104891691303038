import { createSlice } from '@reduxjs/toolkit'
import { fetchNotifications, fetchOneNotification } from './notificationsThunk'

const initialState = {
  notifications: [],
  status: 'idle',
  error: null,
  notification: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNotification: (state) => {
      state.notification = initialState.notification
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.notifications = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchNotifications.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
    builder.addCase(fetchOneNotification.pending, (state) => {
      state.notification.status = 'loading'
    })
    builder.addCase(fetchOneNotification.fulfilled, (state, action) => {
      state.notification.status = 'succeeded'
      state.notification.data = action.payload.payload
    })
    builder.addCase(fetchOneNotification.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetNotification } = notificationSlice.actions

export const reducer = notificationSlice.reducer

export default notificationSlice
