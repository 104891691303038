import { Avatar, Menu } from '@mui/material'
import { styled } from '@mui/system'

export const StyledUserAvatar = styled(Avatar)(({ theme }) => ({
  height: 32,
  width: 32,
  padding: '4px',
  border: '1px solid #f1f5f9'
}))

export const StyledAccountMenu = styled(Menu)(({ theme }) => ({
  '.MuiPopover-paper': {
    minWidth: '180px'
  }
}))
