import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchCart = createAsyncThunk(
  'cart/fetchCart',
  async ({ id, payload }, { dispatch }) => {
    let data
    try {
      const response = await axiosPrivate.get(`/api/carts/admin/me/${id}`, {
        ...payload
      })

      data = await response.data
      if (data.status === 'success') {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message)
    }
  }
)

export const incrementItem = createAsyncThunk(
  'cart/incrememntItem',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/carts/admin/item/inc/${id}`, {
        product_id: payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
export const decrementItem = createAsyncThunk(
  'cart/incrememntItem',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/carts/admin/item/dec/${id}`, {
        product_id: payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
export const emptyCart = createAsyncThunk('cart/emptyCart', async (id, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/carts/admin/empty/${id}`)
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})
export const deleteItem = createAsyncThunk('cart/emptyCart', async ({ id, payload }, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/carts/admin/item/remove/${id}`, {
      data: { product_id: payload }
    })
    if (response.status === 200) {
      data = await response.data
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message || 'Unknown error occurred')
  }
})
export const applyPromoCode = createAsyncThunk(
  'promoCodes/applyPromoCode',
  async ({ id, code }) => {
    let data
    try {
      const response = await axiosPrivate.post(`/api/carts/admin/code/${id}`, {
        code
      })
      data = await response.data
      if (response.status === 200) {
        return { data }
      }
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
export const addItemsToCart = createAsyncThunk(
  'cart/incrememntItem',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/carts/admin/items/add/${id}`, {
        products: payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
