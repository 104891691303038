import { createSlice } from '@reduxjs/toolkit'
import {
  fetchReductionTypes,
  fetchOneReductionType,
  createReductionType,
  deleteReductionType,
  updateReductionType
} from './reductionTypesThunk'

const initialState = {
  reductionTypes: [],
  status: 'idle',
  error: null,
  reductionType: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const reductionTypesSlice = createSlice({
  name: 'reductionTypes',
  initialState,
  reducers: {
    resetReductionType: (state) => {
      state.reductionType = initialState.reductionType
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReductionTypes.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchReductionTypes.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.reductionTypes = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchReductionTypes.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneReductionType.pending, (state) => {
      state.reductionType.status = 'loading'
    })
    builder.addCase(fetchOneReductionType.fulfilled, (state, action) => {
      state.reductionType.status = 'succeeded'
      state.reductionType.data = action.payload.payload
    })
    builder.addCase(fetchOneReductionType.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createReductionType.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createReductionType.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.reductionTypes = [
        payload,
        state.reductionTypes.filter((item) => item?.id !== payload?.id)
      ]
    })
    builder.addCase(createReductionType.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteReductionType.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteReductionType.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.reductionTypes.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteReductionType.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateReductionType.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateReductionType.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.reductionTypes.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateReductionType.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetReductionType } = reductionTypesSlice.actions

export const reducer = reductionTypesSlice.reducer

export default reductionTypesSlice
