import { createSlice } from '@reduxjs/toolkit'
import { fetchStates, fetchOneState, createState, deleteState, updateState } from './statesThunk'

const initialState = {
  states: [],
  status: 'idle',
  error: null,
  state: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const statesSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
    resetState: (state) => {
      state.state = initialState.state
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStates.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchStates.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.states = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchStates.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneState.pending, (state) => {
      state.state.status = 'loading'
    })
    builder.addCase(fetchOneState.fulfilled, (state, action) => {
      state.state.status = 'succeeded'
      state.state.data = action.payload.payload
    })
    builder.addCase(fetchOneState.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createState.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createState.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.states = [payload, state.states.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createState.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteState.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteState.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.states.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteState.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateState.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateState.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.states.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateState.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetState } = statesSlice.actions

export const reducer = statesSlice.reducer

export default statesSlice
