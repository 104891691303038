import { useState, useRef } from 'react'
import { MenuItem, ButtonBase, Divider } from '@mui/material'
import { Box } from '@mui/system'
import { StyledUserAvatar, StyledAccountMenu } from './Styled'
import useAuth from 'src/hooks/useAuth'
import avatar from 'src/assets/images/avatar.png'
import { useTranslation } from 'react-i18next'

const AccountMenu = () => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const { user, logout } = useAuth()
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await logout()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <StyledUserAvatar alt="User" src={avatar} />
      </Box>
      <StyledAccountMenu
        onClose={handleClose}
        keepMounted
        anchorEl={ref.current}
        open={isOpen}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem disabled>{user?.full_name}</MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <i className="fa-solid fa-right-from-bracket"></i>&nbsp;&nbsp;{t('logout')}
        </MenuItem>
      </StyledAccountMenu>
    </>
  )
}

export default AccountMenu
