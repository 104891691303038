import { createSlice } from '@reduxjs/toolkit'
import {
  fetchTransactions,
  fetchOneTransaction,
  createTransaction,
  deleteTransaction,
  updateTransaction,
  fetchTransactionsByUserId
} from './transactionsThunk'

const initialState = {
  transactions: [],
  status: 'idle',
  error: null,
  transaction: {
    data: [],
    status: 'idle',
    error: null
  },
  userTransactions: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    resetTransaction: (state) => {
      state.transaction = initialState.transaction
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.transactions = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchTransactions.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchOneTransaction.pending, (state) => {
      state.transaction.status = 'loading'
    })
    builder.addCase(fetchOneTransaction.fulfilled, (state, action) => {
      state.transaction.status = 'succeeded'
      state.transaction.data = action.payload.payload
    })
    builder.addCase(fetchOneTransaction.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(createTransaction.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createTransaction.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const payload = action?.payload?.data
      state.transactions = [payload, state.transactions.filter((item) => item?.id !== payload?.id)]
    })
    builder.addCase(createTransaction.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(deleteTransaction.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(deleteTransaction.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.transactions.filter((item) => item.id !== payload.id)
    })
    builder.addCase(deleteTransaction.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(updateTransaction.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.transactions.filter((item) => item.id !== action.payload.id)
    })
    builder.addCase(updateTransaction.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })

    builder.addCase(fetchTransactionsByUserId.pending, (state) => {
      state.userTransactions.status = 'loading'
    })
    builder.addCase(fetchTransactionsByUserId.fulfilled, (state, action) => {
      state.userTransactions.status = 'succeeded'
      state.userTransactions.data = action.payload.payload
    })
    builder.addCase(fetchTransactionsByUserId.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetTransaction } = transactionsSlice.actions

export const reducer = transactionsSlice.reducer

export default transactionsSlice
