import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'src/utils/axios'

export const fetchLockers = createAsyncThunk('lockers/fetchLockers', async (query) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/lockers`, {
      params: query
    })
    data = await response.data
    if (data.status === 'success') {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const fetchOneLocker = createAsyncThunk('lockers/fetchLocker', async (id, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.get(`/api/lockers/${id}`)
    data = await response.data
    if (response.status === 200) {
      return data
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const createLocker = createAsyncThunk('lockers/createLocker', async (payload) => {
  let data
  try {
    const response = await axiosPrivate.post(`/api/lockers`, {
      ...payload
    })
    data = await response.data
    if (response.status === 201) {
      return { status: 'success', data }
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    )
  }
})

export const deleteLockers = createAsyncThunk('lockers/deleteLockers', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/lockers/`, {
      data: { ids }
    })
    data = await response.data

    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message
    })
  }
})

export const deleteLocker = createAsyncThunk('lockers/deleteLocker', async (ids, thunkAPI) => {
  let data
  try {
    const response = await axiosPrivate.delete(`/api/lockers/${ids[0]}`)
    data = await response.data
    if (response.status === 200) {
      return { data, status: 'success' }
    }
    throw new Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message)
  }
})

export const updateLocker = createAsyncThunk(
  'lockers/updateLocker',
  async ({ id, payload }, thunkAPI) => {
    let data
    try {
      const response = await axiosPrivate.put(`/api/lockers/${id}`, {
        ...payload
      })
      data = await response.data
      if (response.status === 200) {
        return { status: 'success', data }
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      )
    }
  }
)
