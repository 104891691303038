import { createSlice } from '@reduxjs/toolkit'
import { fetchRoles } from './rolesThunk'

const initialState = {
  roles: [],
  status: 'idle',
  error: null,
  role: {
    data: [],
    status: 'idle',
    error: null
  },
  metadata: {}
}

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetRole: (state) => {
      state.role = initialState.role
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoles.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const { payload } = action.payload
      state.roles = payload?.payload
      state.metadata = payload?.metadata
    })
    builder.addCase(fetchRoles.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    })
  }
})

export const { resetRole } = rolesSlice.actions

export const reducer = rolesSlice.reducer

export default rolesSlice
