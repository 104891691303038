import { PATH } from 'src/routes/paths'

export const PAGES = [
  {
    subHeader: '',
    items: [
      {
        link: PATH.DASHBOARD,
        label: 'dashboard',
        icon: 'fa-solid fa-house'
      }
    ]
  },
  {
    subHeader: 'resources',
    items: [
      {
        link: PATH.ORDERS,
        label: 'orders',
        icon: 'fa-solid fa-cart-shopping'
      },
      {
        link: PATH.LOCKERS,
        label: 'lockers',
        icon: 'fa-solid fa-lock'
      },
      {
        link: PATH.BOX,
        label: 'boxes',
        icon: 'fa-solid fa-box'
      },

      {
        link: PATH.STATE,
        label: 'states',
        icon: 'fa-solid fa-building'
      },
      {
        link: PATH.TRANSACTIONS,
        label: 'transactions',
        icon: 'fa-solid fa-rotate'
      },
      {
        link: PATH.GROUP,
        label: 'groups',
        icon: 'fa-solid fa-people-group'
      },
      {
        link: PATH.NOTIFICATIONS,
        label: 'notifications',
        icon: 'fa-solid fa-bell'
      },
      {
        link: PATH.PRODUCTS,
        label: 'products',
        icon: 'fa-solid fa-archive'
      },
      {
        link: PATH.USERS,
        label: 'users',
        icon: 'fa-solid fa-user'
      },
      {
        link: PATH.DELIVERY,
        label: 'delivery-person',
        icon: 'fa-solid fa-truck'
      },
      {
        link: PATH.ADMINS,
        label: 'admins',
        icon: 'fa-solid fa-hammer'
      },
      {
        link: PATH.BRACKETS,
        label: 'main-categories',
        icon: 'fa-solid fa-layer-group'
      },
      {
        link: PATH.CATEGORIES,
        label: 'sub-categories',
        icon: 'fa-solid fa-sitemap'
      },
      {
        link: PATH.SERVICES,
        label: 'services',
        icon: 'fa-solid fa-solid fa-gears'
      },
      {
        link: PATH.PROMOCODE,
        label: 'promo-code',
        icon: 'fa-solid fa-gift'
      },
      {
        link: PATH.SHIPPINGCOST,
        label: 'shipping-cost',
        icon: 'fa-solid fa-dollar'
      }
    ]
  }
]
