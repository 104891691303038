import { createSlice } from '@reduxjs/toolkit'

const getTheme = () => {
  return localStorage?.getItem('theme') || 'light'
}

const initialState = {
  mode: getTheme()
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      localStorage.setItem('theme', state.mode === 'light' ? 'dark' : 'light')
      state.mode = state.mode === 'light' ? 'dark' : 'light'
    }
  }
})

export const { toggleTheme } = themeSlice.actions

export default themeSlice.reducer
