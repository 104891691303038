import React, { Fragment, lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import GuestGuard from './guards/guestGuard'
import AuthGuard from './guards/authGuard'
import DashboardLayout from 'src/layout/dashboardLayout/DashboardLayout'
import { PATH } from './paths'

export const renderRoutes = (routes = []) => {
  return routes?.map((route, index) => {
    const Guard = route?.guard || Fragment
    const Layout = route?.layout || Fragment
    const Component = route?.component || Fragment

    return route?.layout ? (
      <Route
        key={index}
        index={route?.index || false}
        exact={route?.exact}
        path={route?.path}
        element={
          <Guard>
            <Layout />
          </Guard>
        }
      >
        {route?.routes && renderRoutes(route?.routes)}
      </Route>
    ) : (
      <Route
        key={index}
        path={route?.path}
        exact={route?.exact}
        element={
          <Guard>
            <Component />
          </Guard>
        }
      />
    )
  })
}

export const setUpRoutes = (routes = []) => {
  return <Routes>{renderRoutes(routes)}</Routes>
}

const routes = [
  {
    exact: true,
    path: PATH.ROOT,
    guard: GuestGuard,
    component: () => <Navigate to="/login" />
  },
  {
    exact: true,
    guard: GuestGuard,
    path: PATH.LOGIN,
    component: lazy(() => {
      return Promise.all([
        import('../views/login/Login'),
        new Promise((resolve) => setTimeout(resolve, 1000))
      ]).then(([moduleExports]) => moduleExports)
    })
  },
  {
    path: PATH.DASHBOARD,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      // DASHBOARD
      {
        path: PATH.INDEX,
        index: true,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/dashboard/Dashboard'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.DASHBOARD,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/dashboard/Dashboard'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // ORDERS
      {
        path: PATH.ORDERS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/orders/OrdersList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.ORDERS_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/orders/OrderUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.ORDERS_UPDATE_PRODUCTS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/orders/OrderProductsUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.ORDERS_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/orders/OrderUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.ORDERS_DETAILS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/orders/OrdersDetails'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // LOCKERS
      {
        path: PATH.LOCKERS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/lockers/LockersList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.LOCKERS_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/lockers/LockerCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.LOCKERS_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/lockers/LockerUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // BOX
      {
        path: PATH.BOX,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/boxes/BoxesList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.BOX_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/boxes/BoxesCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.BOX_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/boxes/BoxesUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // state
      {
        path: PATH.STATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/residences/ResidencesList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.STATE_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/residences/ResidenceCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.STATE_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/residences/ResidenceUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // TRANSACTIONS
      {
        path: PATH.TRANSACTIONS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/transactions/TransactionsList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      //GROUPS
      {
        path: PATH.GROUP,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/groups/GroupList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.GROUP_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/groups/GroupUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.GROUP_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/groups/GroupCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.GROUP_DETAILS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/groups/GroupDetails'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      //NOTIFICATIONS
      {
        path: PATH.NOTIFICATION,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/notifications/NotificationsList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.NOTIFICATION_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/notifications/NotificationCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.NOTIFICATION_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/notifications/NotificationsUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.NOTIFICATION_DETAILS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/notifications/NotificationDetails'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // PRODUCTS
      {
        path: PATH.PRODUCTS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/products/ProductsList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.PRODUCTS_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/products/ProductCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.PRODUCTS_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/products/ProductUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // USERS
      {
        path: PATH.USERS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UsersList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.USERS_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.USERS_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.USERS_DETAILS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserDetails'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.USERS_CART,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserCart'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.ADD_CART,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../features/users/AddToCartPage'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.USERS_ORDER,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserOrder'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // CATEGORIES
      {
        path: PATH.CATEGORIES,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/category/CategoryList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.CATEGORIES_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/category/CategoryCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.CATEGORIES_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/category/CategoryUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // BRACKETS
      {
        path: PATH.BRACKETS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/brackets/BracketsList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.BRACKETS_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/brackets/BracketCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.BRACKETS_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/brackets/BracketUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      //DELIVERYMEN
      {
        path: PATH.DELIVERY,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/deliverymen/DeliveryList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.DELIVERY_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/deliverymen/DeliveryCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.DELIVERY_STATS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../features/users/components/deliveryStats/DeliveryStats'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.DELIVERY_DETAILS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserDetails'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.DELIVERY_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      //ADMINS
      {
        path: PATH.ADMINS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/admins/Admins'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.ADMINS_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/admins/AdminsCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.ADMINS_DETAILS,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserDetails'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.ADMINS_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/users/UserUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // SERVICES
      {
        path: PATH.SERVICES,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/services/ServicesList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.SERVICES_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/services/ServiceCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.SERVICES_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/services/ServiceUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // PROMOCODE
      {
        path: PATH.PROMOCODE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/promoCodes/PromoCodesList'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.PROMOCODE_CREATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/promoCodes/PromoCodeCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },
      {
        path: PATH.PROMOCODE_UPDATE,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/promoCodes/PromoCodeUpdate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      },

      // SHIPPINGCOST
      {
        path: PATH.SHIPPINGCOST,
        exact: true,
        component: lazy(() => {
          return Promise.all([
            import('../views/shippingCost/ShippingCostCreate'),
            new Promise((resolve) => setTimeout(resolve, 1000))
          ]).then(([moduleExports]) => moduleExports)
        })
      }
    ]
  },
  {
    path: PATH.ERROR,
    exact: false,
    component: lazy(() => {
      return Promise.all([
        import('../views/notFound/NotFound'),
        new Promise((resolve) => setTimeout(resolve, 1000))
      ]).then(([moduleExports]) => moduleExports)
    })
  }
]

export default routes
